<template>
  <div class="majoitus">
    <Hero :img="img" :title="title" :text="text" />
    <Content :content="topText" />
    <MediaText
      :header="priceHeader"
      :text="firstText"
      :img="liftImg"
      :mediaFirst="true"
    />
    <Gallery :images="images" columns="4" />
    <MediaText
      :header="activityHeader"
      :text="activityText"
      :link="activityLink"
      :img="activityImg"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import Content from "@/components/Content.vue";
import MediaText from "@/components/MediaText.vue";
import Gallery from "@/components/Gallery.vue";

export default {
  name: "Piilopirtti",
  components: {
    Hero,
    Content,
    MediaText,
    Gallery
  },
  data() {
    return {
      title: "Riekkola",
      img: {
        name: "riekkola.jpeg",
        alt: "Riekkola - Piilopirtti"
      },
      liftImg: {
        name: "auringonlasku.jpg",
        alt: "auringonlasku laiturilta"
      },
      text: null,
      topText:
        "Riekkola – Ison Akanjärven hiekkapohjaisella rannalla oleva Riekkola on monen suosikki. Erähenkiseen mökkiin mahtuu hyvin nukkumaan 5 vierasta. Matalapohjainen ranta on lapsiperheille turvallinen uimapaikka. Lämmitys hoituu puulla, takka ja kamina pitävät tämän pirtin varmasti riittävän lämpimänä, vaikka talvellakin.  Mökissä on varusteluina keittonurkkaus perusastiastoineen, kaasuliesi, kaasujääkaappi, radio. Valaistus hoituu akun avulla. Puulämmitteinen rantasauna ja soutuvene ovat vapaassa käytössä.",
      priceHeader: "Hinnasto",
      firstText:
        "<p>Kesä: Toukokuu – Lokakuu: 80€/vrk<br />Talvi: Marraskuu-Huhtikuu: 100€/vrk</p><h3>Lisäpalvelut</h3><p>Liinavaatesetti (petivaatteet, pyyhe, pefletti): 20€ Loppusiivous: 120€</p><p>Eräopas retkeilyyn, kalastukseen ja metsästykseen: ½ päivää 120€, koko päivä 200€.</p><p>Marjasuvannon isännältä löytyy omasta takaa Suivakon kennelistä osaavaa koirakaverikalustoa lintu- ja hirvijahteihin, kysy lisätietoja ja tarjousta valmiiksi pureskelluista jahtipaketeista!</p><h3>Vuokravälineet:</h3><ul><li>Extrasoutuvene pelastusliiveineen: 30€/vrk</li><li>Maastopyörä: 25€/vrk</li><li>Kanootti varusteineen: 30€/vrk</li><li>Lumikengät: 10€/vrk</li><li>Murtomaahiihtosetti 10€/vrk</li><li>Kalastusvälinesetti kesä/talvi: 10€/vrk</li></ul>",
      images: [
        {
          src: "riekkola.jpeg",
          alt: "Riekkola - Piilopirtti"
        },
        {
          src: "riekkola_2.jpg",
          alt: "Riekkola - Piilopirtti"
        },
        {
          src: "riekkola_sauna2.jpg",
          alt: "Riekkola - Piilopirtin sauna"
        },
        {
          src: "riekkola_sauna.jpg",
          alt: "Riekkola - Piilopirtin sauna"
        },
        {
          src: "iltanuotiolla.jpg",
          alt: "Iltanuotio laavulla"
        },
        {
          src: "auringonlasku.jpg",
          alt: "Auringonlasku laiturilta"
        },
        {
          src: "riekkola_sisalta.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta2.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta3.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta4.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta5.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta6.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta7.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta8.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sisalta9.jpg",
          alt: "Riekkola sisältä"
        },
        {
          src: "riekkola_sauna__2.jpg",
          alt: "Riekkola - Piilopirtin sauna"
        },
        {
          src: "riekkola_sauna2__2.jpg",
          alt: "Riekkola - Piilopirtin sauna"
        },
        {
          src: "riekkola_sauna4.jpg",
          alt: "Riekkola - Piilopirtin saunan vilpola"
        },
        {
          src: "riekkola_piha.jpg",
          alt: "Riekkola - pöytä ja penkit"
        },
        {
          src: "riekkola_piha2.jpg",
          alt: "Riekkola - Laavu"
        },
        {
          src: "riekkola_piha4.jpg",
          alt: "Riekkola - Piha mökin edestä"
        },
        {
          src: "riekkola_piha5.jpg",
          alt: "Riekkola - Piha mökin edestä"
        },
        {
          src: "riekkola_maisema2.jpg",
          alt: "Riekkola - Maisema rannasta"
        },
        {
          src: "riekkola_maisema3.jpg",
          alt: "Riekkola - Maisema rannasta"
        }
      ],
      activityHeader: "Ohjelmapalvelut",
      activityText:
        "Luonnossa liikkumiseen, marjastukseen, kalastukseen, metsästykseen tai vaikka vaan oleiluun tila tarjoaa mukavat puitteet. Tilalta löytyy merkittyjä retkeilyreittejä tulistelupaikkoineen, veneitä vesillä liikkumiseen ja opaspalveluita mihin tahansa luontoaktiviteettiin. Lähiseudun parhaat marjapaikat, kalavedet ja riistamaat asiantuntevan oppaan kanssa antavat varmasti enemmän elämyksiä kuin moni osaa odottaakaan.",
      activityLink: {
        path: "/tekemista",
        name: "Tekemistä"
      },
      activityImg: {
        name: "ohjelma.jpg",
        alt: ""
      }
    };
  }
};
</script>
