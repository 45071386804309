<template>
  <div class="majoitus">
    <Hero :img="img" :title="title" :text="text" />
    <Content :content="topText" />
    <MediaText
      :header="priceHeader"
      :text="firstText"
      :img="liftImg"
      :mediaFirst="true"
    />
    <Gallery :images="images" columns="4" />
    <MediaText
      :header="activityHeader"
      :text="activityText"
      :link="activityLink"
      :img="activityImg"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import Content from "@/components/Content.vue";
import MediaText from "@/components/MediaText.vue";
import Gallery from "@/components/Gallery.vue";

export default {
  name: "Pyorohirsimokki",
  components: {
    Hero,
    Content,
    MediaText,
    Gallery
  },
  data() {
    return {
      title: "Haukkala",
      img: {
        name: "haukkala.jpg",
        alt: "Haukkala - pyöröhirsimökki"
      },
      liftImg: {
        name: "sauna-ja-laavu.jpg",
        alt: "Sauna ja laavu Kemijoen rannalla"
      },
      text: null,
      topText:
        "Haukkala sijaitsee Kemijoen komealla törmällä. Petipaikkoja Haukkalasta löytyy viidelle vieraalle. Peruslämmitys hoituu sähköllä. Mökissä on varusteluina pieni keittiö perusastiastoineen, liesi, mikro, kahvinkeitin, jääkaappi, radio, televisio ja rubikin kuutio. Puulämmitteinen rantasauna, laavu ja soutuvene ovat mökkivieraiden yhteiskäytössä.",
      priceHeader: "Hinnasto",
      firstText:
        "<p>Kesä: Toukokuu – Lokakuu: 80€/vrk<br />Talvi: Marraskuu-Huhtikuu: 100€/vrk</p><h3>Lisäpalvelut</h3><p>Liinavaatesetti (petivaatteet, pyyhe, pefletti): 20€ Loppusiivous: 120€</p><p>Eräopas retkeilyyn, kalastukseen ja metsästykseen: ½ päivää 120€, koko päivä 200€.</p><p>Marjasuvannon isännältä löytyy omasta takaa Suivakon kennelistä osaavaa koirakaverikalustoa lintu- ja hirvijahteihin, kysy lisätietoja ja tarjousta valmiiksi pureskelluista jahtipaketeista!</p><h3>Vuokravälineet:</h3><ul><li>Extrasoutuvene pelastusliiveineen: 30€/vrk</li><li>Maastopyörä: 25€/vrk</li><li>Kanootti varusteineen: 30€/vrk</li><li>Lumikengät: 10€/vrk</li><li>Murtomaahiihtosetti 10€/vrk</li><li>Kalastusvälinesetti kesä/talvi: 10€/vrk</li></ul>",
      images: [
        {
          src: "haukkala.jpg",
          alt: "Haukkala ulkoa"
        },
        {
          src: "haukkala_sisalta.jpeg",
          alt: "Tunnelma sisältä"
        },
        {
          src: "sauna.jpeg",
          alt: "Sauna talvella"
        },
        {
          src: "haukkala_talvella.jpeg",
          alt: "Haukkala talvella"
        },
        {
          src: "haukkala_sisalta4.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "haukkala_sisalta8.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "haukkala_sisalta5.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "haukkala_sisalta3.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "haukkala_sisalta2.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "haukkala_sisalta6.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "haukkala_sisalta.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "haukkala_sisalta7.jpg",
          alt: "Haukkala sisältä"
        },
        {
          src: "sauna_laavu1.jpg",
          alt: "Maisema laavulta"
        },
        {
          src: "sauna_laavu3.jpg",
          alt: "Laavu"
        },
        {
          src: "sauna_laavu5.jpg",
          alt: "Joki ja vene"
        },
        {
          src: "sauna_laavu9.jpg",
          alt: "Sauna"
        }
      ],
      activityHeader: "Ohjelmapalvelut",
      activityText:
        "Luonnossa liikkumiseen, marjastukseen, kalastukseen, metsästykseen tai vaikka vaan oleiluun tila tarjoaa mukavat puitteet. Tilalta löytyy merkittyjä retkeilyreittejä tulistelupaikkoineen, veneitä vesillä liikkumiseen ja opaspalveluita mihin tahansa luontoaktiviteettiin. Lähiseudun parhaat marjapaikat, kalavedet ja riistamaat asiantuntevan oppaan kanssa antavat varmasti enemmän elämyksiä kuin moni osaa odottaakaan.",
      activityLink: {
        path: "/tekemista",
        name: "Tekemistä"
      },
      activityImg: {
        name: "kalalla.jpg",
        alt: ""
      }
    };
  }
};
</script>
