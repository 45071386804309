<template>
  <div class="majoitus">
    <Hero :img="img" :title="title" :text="text" />
    <Content :content="topText" :ingressi="true" />
    <Content :content="middleText" />
    <Columns :columns="columns" />
    <Content :content="bottomText" />
    <Gallery :images="images" />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Content from "@/components/Content.vue";
import Columns from "@/components/Columns.vue";

export default {
  name: "Majoitus",
  components: {
    Hero,
    Content,
    Columns,
    Gallery
  },
  data() {
    return {
      title: "Majoitus",
      img: {
        name: "sauna-ja-laavu.jpg",
        alt: ""
      },
      text: null,
      topText:
        "Marjasuvannon lomamajoilla on vuokrattavana siistejä kotoisen lämpimiä mökkejä. Mökeissä on omat rantasaunat, takat ja tarvittavat astiat. Kahdessa mökissä on sähköt, takka, tv ja mikrokin. Mökkeihin mahtuu hyvin viisi henkilöä.",
      middleText:
        "Harrastusmahdollisuudet ovat monipuoliset. Kaksi mökeistä ja rantasauna on Kemijoen rannalla. Kemijoen pääasialliset saaliskalat ovat harjus ja taimen. Läheisen Akanjärven rannalla sijaitsee yksi mökeistä, järvi antaa saaliikseen siikaa ja ahventa. Lähiseudulla on myös kunnostettuja tammukkapuroja.",
      bottomText:
        "Marjasuvannon lomamajojen ympäristössä voi harrastaa myös monenlaista muuta. Metsästykseen on hyvät puitteet, alueella on runsas jäniskanta ja vaihtelevasti metsäkanalintuja. Läheinen Kemijoen silta mahdollistaa valtion maiden metsästyskäytön joen itä- ja länsipuolella, sekä Pelkosenniemen itäisen metsästysalueen. Lähistöllä on kaksi merkittävää retkeilyreittiä, upeiden maisemien keskellä kohoavilla Nivatunturilla ja Kivitunturilla. Maastossa on useita polkuja vanhoja tieuria ym. patikoida tai pyöräillä oman mieltymyksen mukaan. Jokivarrelta ja Akanjärven rannalla on tasokkaat laavut taukopaikoiksi. Syksyisin voi poimia sieniä, mustikoita, puolukoita ja tietysti Lapin kultaa eli hilloja elokuulla.",
      images: [
        {
          src: "leirinta2.jpg",
          alt: "Leirintäalue"
        },
        {
          src: "aitta.jpg",
          alt: "Aitta"
        },
        {
          src: "leirinta.jpg",
          alt: "Leirintäalue"
        }
      ],
      columns: [
        {
          img: {
            name: "haukkala_s.jpg",
            alt: "Haukkala - pyötöhirsimökki"
          },
          name: "Haukkala",
          text: "",
          link: {
            name: "Tutustu ja ihastu",
            path: "/majoitus/pyorohirsimokki"
          }
        },
        {
          img: {
            name: "pollola_s.jpg",
            alt: "Pöllölä - Höylähirsimökki"
          },
          name: "Pöllölä",
          text: "",
          link: {
            name: "Tutustu ja ihastu",
            path: "/majoitus/hoylahirsimokki"
          }
        },
        {
          img: {
            name: "riekkola.jpeg",
            alt: "Riekkola - Piilopirtti"
          },
          name: "Riekkola",
          text: "",
          link: {
            name: "Tutustu ja ihastu",
            path: "/majoitus/piilopirtti"
          }
        }
      ]
    };
  }
};
</script>
